import { defaultTitle } from "@/router";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { pinia } from "./pinia";
import { i18n, setLanguageAsync } from "./i18n";
import { hakoLibVuePlugin } from "@hakosalo/hakolib";

const app = createApp(App).use(router).use(pinia).use(i18n).use(hakoLibVuePlugin);

(async () => {
	await setLanguageAsync("fi");

	window.document.title = defaultTitle;
	app.mount("#app");
})();
