
import { defineComponent, PropType } from "vue";
import { SelectOption } from "@/types/interfaces/field";

export default defineComponent({
	props: {
		modelValue: {
			required: true,
		},
		options: {
			type: Array as PropType<SelectOption[]>,
			required: true,
		},
		inline: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		modelValueComputed: {
			get(): any {
				return this.modelValue as any;
			},
			set(newVal: any) {
				this.$emit("update:modelValue", newVal);
			},
		},
	},
	data() {
		return {
			id: new Date().valueOf(),
		};
	},
});
