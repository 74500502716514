import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "entity-field-radio" }
const _hoisted_2 = ["id", "name", "value", "disabled"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: option.value?.toString(),
        class: _normalizeClass(["form-check", {
				'form-check-inline': _ctx.inline,
			}])
      }, [
        _withDirectives(_createElementVNode("input", {
          class: "form-check-input",
          type: "radio",
          id: `efr-${_ctx.id}-${index}`,
          name: `efr-${_ctx.id}-${index}`,
          value: option.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValueComputed) = $event)),
          disabled: option.disabled === true
        }, null, 8, _hoisted_2), [
          [_vModelRadio, _ctx.modelValueComputed]
        ]),
        _createElementVNode("label", {
          class: "form-check-label",
          for: `efr-${_ctx.id}-${index}`
        }, _toDisplayString(option.text), 9, _hoisted_3)
      ], 2))
    }), 128))
  ]))
}