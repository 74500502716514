import { useAppStore } from "@/pinia/appStore";
import { computed } from "vue";
import { useRouter } from "vue-router";
import router from "@/router";

const logoUrl = computed(() => {
	const appStore = useAppStore();
	// const router = useRouter();

	if (!router) {
		return "r";
	}

	const route = router.currentRoute.value;

	if (!route) {
		return "x";
	}

	let formName = route.meta.formName;
	if (!formName) {
		formName = "companyForm";
	}

	const identifier = appStore.organizationInfo?.organizationIdentifier;
	if (!identifier) {
		return "y";
	}

	// /public/publicform/publicform/logo/companyForm/crm2_jussi_conversiontool
	const apiUrl = process.env["VUE_APP_PUBLICFORMAPI"];
	return `${apiUrl}/public/publicForm/publicForm/logo/${formName}/${identifier}`;
});

export { logoUrl };
