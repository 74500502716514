<template>
	<div>
		<div class="card">
			<div class="card-body">
				<h3>
					{{ $t("generic.thankYou") }}
				</h3>

				<p>{{ $t("generic.dataHasBeenSaved") }}</p>
				<!-- <div>Tallennus onnistui. Voit kirjautua järjestelmään osoitteesta:</div>
				<a href="https://elinvoimaa.hakosalo.fi/">https://elinvoimaa.hakosalo.fi/</a> -->
			</div>
		</div>
	</div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
