import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import PageNotFound from "../views/404View.vue";
import CompanyFormView from "../views/CompanyFormView.vue";
import StartupperFormView from "../views/StartupperFormView.vue";
import ImmigrantFormView from "../views/ImmigrantFormView.vue";
import ThankYouView from "../views/ThankYouView.vue";
import SaveFailedView from "../views/SaveFailedView.vue";
import { i18n, setLanguageAsync } from "@/i18n";
import CompanyUpdateFormView from "@/views/CompanyUpdateFormView.vue";
import JobSeekerFormView from "@/views/JobSeekerFormView.vue";

export const defaultTitle = "Hakosalo lomake";

// Hard-type meta object.
declare module "vue-router" {
	interface RouteMeta {
		formName?: string;
	}
}

const routes: Array<RouteRecordRaw> = [
	{
		path: "/:lang/",
		name: "home",
		component: PageNotFound,
	},
	{
		path: "/:lang/:org/CompanyForm",
		name: "CompanyForm",
		component: CompanyFormView,
		meta: {
			formName: "companyForm",
		},
	},
	{
		path: "/:lang/:org/CompanyForm/success",
		name: "CompanyFormSuccess",
		component: ThankYouView,
		meta: {
			formName: "companyForm",
		},
	},
	{
		path: "/:lang/:org/CompanyForm/saveFailed",
		name: "CompanyFormFailed",
		component: SaveFailedView,
		meta: {
			formName: "companyForm",
		},
	},
	{
		path: "/:lang/:org/StartupperForm",
		name: "StartupperForm",
		component: StartupperFormView,
		meta: {
			formName: "startupperForm",
		},
	},
	{
		path: "/:lang/:org/StartupperForm/success",
		name: "StartupperFormSuccess",
		component: ThankYouView,
		meta: {
			formName: "startupperForm",
		},
	},
	{
		path: "/:lang/:org/StartupperForm/saveFailed",
		name: "StartupperFormFailed",
		component: SaveFailedView,
		meta: {
			formName: "startupperForm",
		},
	},
	{
		path: "/:lang/:org/IntegrationForm",
		name: "ImmigrantForm",
		component: ImmigrantFormView,
		meta: {
			formName: "integrationForm",
		},
	},
	{
		path: "/:lang/:org/IntegrationForm/success",
		name: "ImmigrantFormSuccess",
		component: ThankYouView,
		meta: {
			formName: "integrationForm",
		},
	},
	{
		path: "/:lang/:org/IntegrationForm/saveFailed",
		name: "ImmigrantFormFailed",
		component: SaveFailedView,
		meta: {
			formName: "integrationForm",
		},
	},
	{
		path: "/:lang/:org/JobSeekerForm",
		name: "JobSeekerForm",
		component: JobSeekerFormView,
		meta: {
			formName: "jobSeekerForm",
		},
	},
	{
		path: "/:lang/:org/JobSeekerForm/success",
		name: "JobSeekerFormSuccess",
		component: ThankYouView,
		meta: {
			formName: "jobSeekerForm",
		},
	},
	{
		path: "/:lang/:org/JobSeekerForm/saveFailed",
		name: "JobSeekerFormFailed",
		component: SaveFailedView,
		meta: {
			formName: "jobSeekerForm",
		},
	},
	{
		path: "/:lang/:org/CompanyUpdateForm/:token",
		name: "CompanyUpdateForm",
		component: CompanyUpdateFormView,
		props: true,
		meta: {
			formName: "companyUpdateForm",
		},
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach((to) => {
	if (!to.params.lang) {
		router.push({
			name: "home",
			params: {
				lang: "fi",
			},
		});
	} else {
		if (i18n.global.locale != to.params.lang) {
			setLanguageAsync(to.params.lang as "fi" | "sv" | "en");
		}
	}
});

export default router;
