<template>
	<div class="card my-3">
		<div class="card-body">
			<slot></slot>
		</div>
	</div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
