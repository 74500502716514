<template>
	<div>
		<div class="card">
			<div class="card-body">
				<h3>
					{{ $t("generic.errorHappened") }}
				</h3>

				<p>{{ $t("generic.dataHasNotBeenSaved") }}</p>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
