import { AxiosRequestConfig } from "axios";
import { DraftCompanyDto } from "@api/draft-company-dto";
import { DraftCompanyFormDataDto } from "@api/draft-company-form-data-dto";
import { FormDataDto } from "@api/form-data-dto";
import { NetboxCompany } from "@api/netbox-company";
import { NetboxCompanyShort } from "@api/netbox-company-short";
import { CompanyFieldCategoryDto } from "@api/company-field-category-dto";
import ApiRespGen, { ApiResponse } from "../apiResponse";
import publicFormApiAxios from "../publicFormApiAxios";
import { SoMeDto } from "@api/so-me-dto";
import { CompanyFormDto } from "@api/company-form-dto";
import { LanguageDto } from "@api/language-dto";
import { Tol2008TreeDto } from "@api/tol2008-tree-dto";
import { CompanyRoleDto } from "@api/company-role-dto";

const controller = "public/publicForm/companyForm";

export default {
	companyForm: {
		get: {
			async getFormInfo(): Promise<ApiResponse<FormDataDto<CompanyFieldCategoryDto>>> {
				try {
					const response = await publicFormApiAxios.get<FormDataDto<CompanyFieldCategoryDto>>(
						`${controller}/`
					);
					return new ApiRespGen<FormDataDto<CompanyFieldCategoryDto>>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<FormDataDto<CompanyFieldCategoryDto>>().autoDetectFailure(ex);
				}
			},
			async getDraft(): Promise<ApiResponse<DraftCompanyFormDataDto>> {
				try {
					const response = await publicFormApiAxios.get<DraftCompanyFormDataDto>(
						`${controller}/Draft`
					);
					return new ApiRespGen<DraftCompanyFormDataDto>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<DraftCompanyFormDataDto>().autoDetectFailure(ex);
				}
			},
			async fields(): Promise<ApiResponse<CompanyFieldCategoryDto[]>> {
				try {
					const response = await publicFormApiAxios.get<CompanyFieldCategoryDto[]>(
						`${controller}/Fields`
					);
					return new ApiRespGen<CompanyFieldCategoryDto[]>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<CompanyFieldCategoryDto[]>().autoDetectFailure(ex);
				}
			},
			async netboxSearchByName(search: string) {
				try {
					const axiosReqConfig = {
						params: {
							name: search,
						},
					} as AxiosRequestConfig;
					const response = await publicFormApiAxios.get<NetboxCompanyShort[]>(
						`${controller}/SearchNetboxName`,
						axiosReqConfig
					);
					return new ApiRespGen<NetboxCompanyShort[]>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<NetboxCompanyShort[]>().autoDetectFailure(ex);
				}
			},
			async netboxSearchByBusinessId(search: string) {
				try {
					const axiosReqConfig = {
						params: {
							businessId: search,
						},
					} as AxiosRequestConfig;
					const response = await publicFormApiAxios.get<NetboxCompany>(
						`${controller}/SearchNetboxVat`,
						axiosReqConfig
					);
					return new ApiRespGen<NetboxCompany>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<NetboxCompany>().autoDetectFailure(ex);
				}
			},
			async somes() {
				try {
					const resp = await publicFormApiAxios.get<SoMeDto[]>(`${controller}/somes`);
					return new ApiRespGen<SoMeDto[]>().success(resp.data);
				} catch (ex: unknown) {
					return new ApiRespGen<SoMeDto[]>().autoDetectFailure(ex);
				}
			},
			async matchNetboxCompanyForm(form: string) {
				try {
					const axiosReqConfig = {
						params: {
							form: form,
						},
					};
					const resp = await publicFormApiAxios.get<CompanyFormDto | null>(
						`${controller}/matchNetboxCompanyForm`,
						axiosReqConfig
					);
					return new ApiRespGen<CompanyFormDto | null>().success(resp.data);
				} catch (ex: unknown) {
					return new ApiRespGen<CompanyFormDto | null>().autoDetectFailure(ex);
				}
			},
			async matchNetboxLanguage(lang: string) {
				try {
					const axiosReqConfig = {
						params: {
							lang: lang,
						},
					};
					const resp = await publicFormApiAxios.get<LanguageDto | null>(
						`${controller}/matchNetboxLanguage`,
						axiosReqConfig
					);
					return new ApiRespGen<LanguageDto | null>().success(resp.data);
				} catch (ex: unknown) {
					return new ApiRespGen<LanguageDto | null>().autoDetectFailure(ex);
				}
			},
			async matchNetboxTol(code: string) {
				try {
					const axiosReqConfig = {
						params: {
							code: code,
						},
					};
					const resp = await publicFormApiAxios.get<Tol2008TreeDto | null>(
						`${controller}/matchNetboxTOL`,
						axiosReqConfig
					);
					return new ApiRespGen<Tol2008TreeDto | null>().success(resp.data);
				} catch (ex: unknown) {
					return new ApiRespGen<Tol2008TreeDto | null>().autoDetectFailure(ex);
				}
			},

			async companyRoles() {
				try {
					const resp = await publicFormApiAxios.get<CompanyRoleDto[]>(
						`${controller}/companyRoles`
					);
					return new ApiRespGen<CompanyRoleDto[]>().success(resp.data);
				} catch (ex: unknown) {
					return new ApiRespGen<CompanyRoleDto[]>().autoDetectFailure(ex);
				}
			},
		},
		post: {
			async default(formData: DraftCompanyFormDataDto): Promise<ApiResponse<DraftCompanyDto>> {
				try {
					const resp = await publicFormApiAxios.post<DraftCompanyDto>(
						`${controller}/Draft`,
						formData
					);

					return new ApiRespGen<DraftCompanyDto>().success(resp.data);
				} catch (ex) {
					return new ApiRespGen<DraftCompanyDto>().autoDetectFailure(ex);
				}
			},
		},
	},
};
