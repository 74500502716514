import { OrganizationInfoDto } from "@api/organization-info-dto";
import ApiRespGen, { ApiResponse } from "../apiResponse";
import publicFormApiAxios from "../publicFormApiAxios";
import { AxiosRequestConfig } from "axios";

const controller = "public/publicForm/publicForm";

export default {
	general: {
		get: {
			async orgInfo(
				organization: string | null = null
			): Promise<ApiResponse<OrganizationInfoDto>> {
				try {
					const config = {} as AxiosRequestConfig;
					if (organization) {
						config.headers!["Organization"] = organization;
					}

					const response = await publicFormApiAxios.get<OrganizationInfoDto>(
						`${controller}/Organization`, config
					);
					return new ApiRespGen<OrganizationInfoDto>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<OrganizationInfoDto>().autoDetectFailure(ex);
				}
			},
			async test(): Promise<ApiResponse<string>> {
				try {
					const response = await publicFormApiAxios.get<string>(`${controller}/`);

					const data = response.data;

					return new ApiRespGen<string>().success(data);
				} catch (ex: unknown) {
					return new ApiRespGen<string>().autoDetectFailure(ex);
				}
			},
		},
	},
};
