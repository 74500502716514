import { DraftStartupperDto } from "@api/draft-startupper-dto";
import { DraftStartupperFormDataDto } from "@api/draft-startupper-form-data-dto";
import { FormDataDto } from "@api/form-data-dto";
import ApiRespGen, { ApiResponse } from "../apiResponse";
import publicFormApiAxios from "../publicFormApiAxios";
import { PersonFieldCategoryDto } from "@api/person-field-category-dto";
import { BusinessIdeaFieldCategoryDto } from "@api/business-idea-field-category-dto";
import { DraftImmigrantFormDataDto } from "@api/draft-immigrant-form-data-dto";
import { DraftImmigrantDto } from "@api/draft-immigrant-dto";
import { DraftJobSeekerFormDataDto } from "@api/draft-job-seeker-form-data-dto";
import { DraftJobSeekerDto } from "@api/draft-job-seeker-dto";

const controller = "public/publicForm/PersonForm";

export default {
	personForm: {
		get: {
			async getFormInfo(
				formName: string
			): Promise<ApiResponse<FormDataDto<PersonFieldCategoryDto>>> {
				try {
					const response = await publicFormApiAxios.get<FormDataDto<PersonFieldCategoryDto>>(
						`${controller}/${formName}`
					);
					return new ApiRespGen<FormDataDto<PersonFieldCategoryDto>>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<FormDataDto<PersonFieldCategoryDto>>().autoDetectFailure(ex);
				}
			},
			async getStartupperDraft(): Promise<ApiResponse<DraftStartupperFormDataDto>> {
				try {
					const response = await publicFormApiAxios.get<DraftStartupperFormDataDto>(
						`${controller}/StartupperDraft`
					);
					return new ApiRespGen<DraftStartupperFormDataDto>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<DraftStartupperFormDataDto>().autoDetectFailure(ex);
				}
			},
			async getImmigrantDraft(): Promise<ApiResponse<DraftImmigrantFormDataDto>> {
				try {
					const response = await publicFormApiAxios.get<DraftImmigrantFormDataDto>(
						`${controller}/ImmigrantDraft`
					);
					return new ApiRespGen<DraftImmigrantFormDataDto>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<DraftImmigrantFormDataDto>().autoDetectFailure(ex);
				}
			},
			async getJobSeekerDraft(): Promise<ApiResponse<DraftJobSeekerFormDataDto>> {
				try {
					const response = await publicFormApiAxios.get<DraftJobSeekerFormDataDto>(
						`${controller}/JobSeekerDraft`
					);
					return new ApiRespGen<DraftJobSeekerFormDataDto>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<DraftJobSeekerFormDataDto>().autoDetectFailure(ex);
				}
			},
			async fields(formName: string): Promise<ApiResponse<PersonFieldCategoryDto[]>> {
				try {
					const response = await publicFormApiAxios.get<PersonFieldCategoryDto[]>(
						`${controller}/Fields/${formName}`
					);
					return new ApiRespGen<PersonFieldCategoryDto[]>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<PersonFieldCategoryDto[]>().autoDetectFailure(ex);
				}
			},
			async businessIdeaFields(): Promise<ApiResponse<BusinessIdeaFieldCategoryDto[]>> {
				try {
					const response = await publicFormApiAxios.get<BusinessIdeaFieldCategoryDto[]>(
						`${controller}/BusinessIdeaFields`
					);
					return new ApiRespGen<BusinessIdeaFieldCategoryDto[]>().success(response.data);
				} catch (ex: unknown) {
					return new ApiRespGen<BusinessIdeaFieldCategoryDto[]>().autoDetectFailure(ex);
				}
			},
		},
		post: {
			async saveStartupper(
				formData: DraftStartupperFormDataDto
			): Promise<ApiResponse<DraftStartupperDto>> {
				try {
					const resp = await publicFormApiAxios.post<DraftStartupperDto>(
						`${controller}/DraftStartupper`,
						formData
					);

					return new ApiRespGen<DraftStartupperDto>().success(resp.data);
				} catch (ex) {
					return new ApiRespGen<DraftStartupperDto>().autoDetectFailure(ex);
				}
			},
			async saveImmigrant(
				formData: DraftImmigrantFormDataDto
			): Promise<ApiResponse<DraftImmigrantDto>> {
				try {
					const resp = await publicFormApiAxios.post<DraftImmigrantDto>(
						`${controller}/DraftImmigrant`,
						formData
					);

					return new ApiRespGen<DraftImmigrantDto>().success(resp.data);
				} catch (ex) {
					return new ApiRespGen<DraftImmigrantDto>().autoDetectFailure(ex);
				}
			},
			async saveJobSeeker(
				formData: DraftJobSeekerFormDataDto
			): Promise<ApiResponse<DraftJobSeekerDto>> {
				try {
					const resp = await publicFormApiAxios.post<DraftJobSeekerDto>(
						`${controller}/DraftJobSeeker`,
						formData
					);

					return new ApiRespGen<DraftJobSeekerDto>().success(resp.data);
				} catch (ex) {
					return new ApiRespGen<DraftJobSeekerDto>().autoDetectFailure(ex);
				}
			},
		},
	},
};
