import { DraftImmigrantFormDataDto } from "@api/draft-immigrant-form-data-dto";
import { DraftJobSeekerFormDataDto } from "@api/draft-job-seeker-form-data-dto";
import { AxiosRequestConfig } from "axios";
import ApiRespGen from "../apiResponse";
import publicFormApiAxios from "../publicFormApiAxios";
import { DraftStartupperFormDataDto } from "@api/draft-startupper-form-data-dto";
import { DraftCompanyFormDataDto } from "@api/draft-company-form-data-dto";
import { CompanyUpdateFormDataDto } from "@api/company-update-form-data-dto";

const baseUrl = "public/publicForm/publicFormValidation";

export default {
	validation: {
		post: {
			async validateImmigrantProperties(
				draftImmigrantForm: DraftImmigrantFormDataDto | null,
				propertyPaths: string[]
			) {
				const axiosReqConfig = {
					params: {
						propertyPaths: propertyPaths,
					},
				} as AxiosRequestConfig;

				type returnType = null;
				const url = `${baseUrl}/ImmigrantProperties`;

				try {
					const response = await publicFormApiAxios.post<returnType>(
						url,
						draftImmigrantForm,
						axiosReqConfig
					);
					return new ApiRespGen<returnType>().success(response.data);
				} catch (ex: any) {
					return new ApiRespGen<returnType>().autoDetectFailure(ex);
				}
			},

			async validateJobSeekerProperties(
				draftJobSeekerForm: DraftJobSeekerFormDataDto | null,
				propertyPaths: string[]
			) {
				const axiosReqConfig = {
					params: {
						propertyPaths: propertyPaths,
					},
				} as AxiosRequestConfig;

				type returnType = null;
				const url = `${baseUrl}/JobSeekerProperties`;

				try {
					const response = await publicFormApiAxios.post<returnType>(
						url,
						draftJobSeekerForm,
						axiosReqConfig
					);
					return new ApiRespGen<returnType>().success(response.data);
				} catch (ex: any) {
					return new ApiRespGen<returnType>().autoDetectFailure(ex);
				}
			},

			async validateStartupperProperties(
				draftStartupperForm: DraftStartupperFormDataDto | null,
				propertyPaths: string[]
			) {
				const axiosReqConfig = {
					params: {
						propertyPaths: propertyPaths,
					},
				} as AxiosRequestConfig;

				type returnType = null;
				const url = `${baseUrl}/StartupperProperties`;

				try {
					const response = await publicFormApiAxios.post<returnType>(
						url,
						draftStartupperForm,
						axiosReqConfig
					);
					return new ApiRespGen<returnType>().success(response.data);
				} catch (ex: any) {
					return new ApiRespGen<returnType>().autoDetectFailure(ex);
				}
			},

			async validateCompanyProperties(
				draftCompanyForm: DraftCompanyFormDataDto | null,
				propertyPaths: string[]
			) {
				const axiosReqConfig = {
					params: {
						propertyPaths: propertyPaths,
					},
				} as AxiosRequestConfig;

				type returnType = null;
				const url = `${baseUrl}/CompanyProperties`;

				try {
					const response = await publicFormApiAxios.post<returnType>(
						url,
						draftCompanyForm,
						axiosReqConfig
					);
					return new ApiRespGen<returnType>().success(response.data);
				} catch (ex: any) {
					return new ApiRespGen<returnType>().autoDetectFailure(ex);
				}
			},

			async validateCompanyUpdateProperties(
				companyUpdateForm: CompanyUpdateFormDataDto | null,
				propertyPaths: string[]
			) {
				const axiosReqConfig = {
					params: {
						propertyPaths: propertyPaths,
					},
				} as AxiosRequestConfig;

				type returnType = null;
				const url = `${baseUrl}/CompanyUpdateProperties`;

				try {
					const response = await publicFormApiAxios.post<returnType>(
						url,
						companyUpdateForm,
						axiosReqConfig
					);
					return new ApiRespGen<returnType>().success(response.data);
				} catch (ex: any) {
					return new ApiRespGen<returnType>().autoDetectFailure(ex);
				}
			},
		},
	},
};
