<template>
	<h3>
		<slot></slot>
	</h3>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
h3 {
	font-size: 1.5rem;
}
</style>
