import axios from "axios";
import { parseISO } from "date-fns";
import { useRoute } from "vue-router";
import { useAppStore } from "@/pinia/appStore";

const publicFormAxios = axios.create({
	baseURL: process.env["VUE_APP_PUBLICFORMAPI"],
	withCredentials: false,
});

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

function isIsoDateString(value: string): boolean {
	return (value && typeof value === "string" && isoDateFormat.test(value)) || false;
}

export function handleDates(body: { [key: string]: unknown }) {
	if (body === null || body === undefined || typeof body !== "object") return body;

	for (const key of Object.keys(body)) {
		const value = body[key];
		if (isIsoDateString(value as string)) body[key] = parseISO(value as string);
		else if (typeof value === "object") handleDates(value as { [key: string]: unknown });
	}
}

publicFormAxios.interceptors.response.use((resp) => {
	handleDates(resp.data);
	return resp;
});

publicFormAxios.interceptors.request.use((config) => {
	const appStore = useAppStore();

	const organization = appStore.organizationIdentifierFromRoute;
	if (organization) {
		config.headers.organization = organization;
	}

	return config;
});

export default publicFormAxios;
