import { EntityFieldTypes } from "../../../../Crm2DBContext/Crm2Context/TS/entity-field-types";
import { ActivityStates } from "../../../../Crm2DBContext/Crm2Context/TS/activity-states";
import { ClassificationInformationTypes } from "../../../../Crm2DBContext/Crm2Context/TS/classification-information-types";
import { PartyTypes } from "../../../../Crm2DBContext/Crm2Context/TS/party-types";
import { PersonTypes } from "../../../../Crm2DBContext/Crm2Context/TS/person-types";
import { PersonUserRoleIds as PersonUserRoles } from "../../../../Crm2DBContext/Crm2Context/TS/person-user-role-ids";
import { ActivityFieldCategories } from "../../../../Crm2DBContext/Crm2Context/TS/activity-field-categories";
import { ActivityFields } from "../../../../Crm2DBContext/Crm2Context/TS/activity-fields";
import { PersonFieldCategories } from "../../../../Crm2DBContext/Crm2Context/TS/person-field-categories";
import { PersonFields } from "../../../../Crm2DBContext/Crm2Context/TS/person-fields";
import { BusinessIdeaFieldCategories } from "../../../../Crm2DBContext/Crm2Context/TS/business-idea-field-categories";
import { BusinessIdeaFields } from "../../../../Crm2DBContext/Crm2Context/TS/business-idea-fields";
import { CompanyFieldCategories } from "../../../../Crm2DBContext/Crm2Context/TS/company-field-categories";
import { CompanyFields } from "../../../../Crm2DBContext/Crm2Context/TS/company-fields";
import { LanguageSkillLevel } from "../../../../Crm2DBContext/Crm2Context/TS/language-skill-level";
import { Genders } from "../../../../Crm2DBContext/Crm2Context/TS/genders";
import { PersonUserRoleIds } from "../../../../Crm2DBContext/Crm2Context/TS/person-user-role-ids";
import { ContactDataType } from "../../../../Crm2DBContext/Crm2Context/TS/contact-data-type";

export const dbEnums = {
	EntityFieldTypes,
	ActivityStates,
	ClassificationInformationTypes,
	PartyTypes,
	PersonTypes,
	PersonUserRoles,
	ActivityFieldCategories,
	ActivityFields,
	PersonFieldCategories,
	PersonFields,
	CompanyFieldCategories,
	CompanyFields,
	BusinessIdeaFieldCategories,
	BusinessIdeaFields,
	LanguageSkillLevel,
	Genders,
	PersonUserRoleIds,
	ContactDataType,
};

export type CompanyFieldCategoriesENUM = CompanyFieldCategories;
export type ActivityFieldCategoriesENUM = ActivityFieldCategories;
export type ActivityFieldsENUM = ActivityFields;
export type PersonFieldCategoriesENUM = PersonFieldCategories;
export type BusinessIdeaFieldCategoriesENUM = BusinessIdeaFieldCategories;
export type ClassificationInformationTypesENUM = ClassificationInformationTypes;
