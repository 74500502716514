<template>
	<div class="center d-flex justify-content-center align-items-center w-100">
		<div>
			<slot></slot>
		</div>
	</div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.center {
	min-height: 100vh;
}
</style>
