import { ISelectOption } from "@hakosalo/hakolib";
import { Ref, computed, onMounted, ref } from "vue";
import { ApiResponse } from "../publicFormApi/apiResponse";

export const useSelectField = <T>(
	single: Ref<T | null | undefined>,
	multi: Ref<T[] | null | undefined>,
	ajax: Ref<boolean>,
	updateSingle: (value: T | null | undefined) => void,
	updateSingleId: (value: T | null | undefined) => void,
	updateMulti: (value: T[] | null | undefined) => void,
	apiGetter: (search?: string, page?: number) => Promise<ApiResponse<T[]>>,
	optionGenerator: (entity: T) => ISelectOption<T>,
	optionIdentifier: (entity: T) => string | number | null | undefined,
	optionsFilter: (entity: T) => boolean = () => true
) => {
	const cValueSingle = computed({
		get: () => single.value,
		set: (value) => {
			updateSingle(value);
			updateSingleId(value);
		},
	});

	const cValueMulti = computed({
		get: () => multi.value || [],
		set: (value) => updateMulti(value),
	});

	const nonAjaxOptions = ref<T[] | undefined>(undefined) as Ref<T[] | undefined>;

	const optionsGetter = async (search?: string, page?: number) => {
		if ((search !== undefined && ajax.value) || !ajax.value) {
			const r = await apiGetter(search, page);
			if (r.isSuccessful && r.data) {
				return r.data as T[];
			}
		}
		return [] as T[];
	};

	onMounted(() => {
		if (multi.value === null) {
			cValueMulti.value = [];
		}

		if (ajax.value == false) {
			optionsGetter().then((r) => (nonAjaxOptions.value = r));
		} else {
			nonAjaxOptions.value = undefined;
		}
	});

	return {
		cValueSingle,
		cValueMulti,
		optionGenerator,
		optionIdentifier,
		optionsGetter,
		nonAjaxOptions,
	};
};
