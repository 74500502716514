import { createI18n } from "vue-i18n";
import axios from "axios";
import publicFormAxios from "./publicFormApi/publicFormApiAxios";
import { config } from "@hakosalo/hakolib";

const loadedLanguages = [] as string[];

const i18n = createI18n({
	locale: process.env.VUE_APP_I18N_LOCALE || "fi",
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "fi",

	messages: {},
});

function setI18nLanguage(lang: "fi" | "sv" | "en") {
	i18n.global.locale = lang;
	axios.defaults.headers.common["Accept-Language"] = lang;
	document.querySelector("html")?.setAttribute("lang", lang);
	config.language = lang;
	return lang;
}

const setLanguageAsync = async (code: "fi" | "sv" | "en") => {
	publicFormAxios.defaults.headers.Language = code;
	return import(/* webpackChunkName: "lang-[request]" */ `@/locales/${code}.json`).then(
		(messages) => {
			i18n.global.setLocaleMessage(code, messages.default);
			loadedLanguages.push(code);
			return setI18nLanguage(code);
		}
	);
};

export { i18n, setLanguageAsync };
